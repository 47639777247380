import React from 'react';
import Layout from '../layouts/mainLayout';

import ContactForm from '../components/contactForm';

export default () => (
  <Layout>
    <ContactForm />
  </Layout>
);
